#SliderCustom{
    
        width: 100%;
        .ant-slider{
            padding-top: 0;
            padding-bottom: 0;
            height: 20px;
            .ant-slider-handle{
                top: 0px;
            }
            .ant-slider-step{
                top:0;
            }
        }
        .ant-slider-rail{
            border-radius:10px;
        }
        .ant-slider-track{
            border-radius:10px 0 0 10px;
        }
        .ant-slider{
            margin: 0;
        }
        .ant-slider-handle{
            // left: 15% !important;
        }
    
}

@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";