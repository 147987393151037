.pose_select {
    .classify_title{
        color:#000000;
        font-size:20px;
        font-weight: bolder;
        text-align: center;
        text-decoration: underline;
    }
    .pose_classify{
        .title{
           color:@senceColor;
           text-align: center;
           font-size: 16px;
           font-weight: bold;
           margin-bottom:5px;
        }
    }
    .pose_list {
        width: 100%;
        height: 300px;
        overflow-y: hidden;
        overflow-x: auto;
        display: flex;
        flex-wrap: nowrap;
        padding: 10px;
       
        .pose_item {
            display: inline-block;
            height: 100%;
            margin-right: 10px;
            font-size: 0;
            position: relative;
            cursor: pointer;
            img {
                border-radius: 10px;

                height: 100%;
                position: relative;
                z-index: 1;
            }
            // &.active::after{
            //     content: '';
            //     display: block;
            //     position: absolute;
            //     left: 0;
            //     top: 0;
            //     width: 100%;
            //     height: 100%;
            //     border:4px solid @pinkColor ;
            //     box-sizing: border-box;
            //     border-radius: 10px;
            //     z-index: 2;
            //     img{

            //     }
            // }
        }
    }

}
.ant-modal .ant-modal-content{
    border: 1px solid #BFD1DF !important;
    background: #F2F7FD !important;
 }
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";