.config_box {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 20px;
    font-size: 14px;
    overflow: auto;
    .classify_title{
        display: flex;
        justify-content: center;
        align-items: center;
        i{
            color:#2197fc;
            font-size: 25px;
            margin-right:10px;
        }
        p{
            color:#000000;
            font-size:20px;
            font-weight: bolder;
            text-align: center;
            text-decoration: underline;
        }
    }
    .desc_box{
        .desc_title{
            color:@senceColor;
            padding:15px 0 6px;
            font-weight: bolder;
            font-size: 18px;
            text-align: center;
        }
    }
    .setting_block {
        display: flex;
        flex-wrap: wrap;
        .setting_size{
            margin-left: 5px;
        }
        &>div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;
            .params_title {
                flex-shrink: 0;
                // margin-right: 3px;
            }

            .params_box {
                // flex: 1;
                display: flex;
                align-items: center;

            }
            .imgWH {
                margin-left:6px;
            }
        }
        &>div:last-child{
            margin-bottom: 0;
        }
        .setting_shape {
            width: 100%;
        }

        .setting_step {
            width: 100%;
            display: block;
            // margin: 10px 0 0;
        }
    }

    .advanced_box {
        .advanced_fixed {
            display: flex;
            align-items: center;
            margin-bottom:8px;
            .params_title {
                text-wrap: nowrap;
            }

            .params_box {
                display: flex;
                align-items: center;
                .ant-switch-checked{
                    background-color:@primaryColor;
                }
                .input div,input{
                    border-radius: 50px;
                    width: 80px;
                    text-align: center;
                }
                .random_icon{
                    border-radius: 50%;
                    background-color: @sliderColorBG;
                    width: 25px;
                    height: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: @primaryColor;
                    font-weight: bold;
                    cursor: pointer;
                    &.disable_stype{
                        background-color: @sliderColorLight;
                        cursor: not-allowed;
                    }
                }
                &>div{
                    margin: 0 2px;
                }
            }
        }
    }

    .slider_title {
        font-weight: bold;
        text-align: center;
    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";