#select_custom{
    border-radius: 20px;
    .ant-select{
        border-radius: 20px;
        text-align: center;
        .ant-select-selector{
            border-radius: 20px;
            background-color: #fff;
        }

    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";