.BusinessLogoCube{
    height: 64px;
    max-width:18px;
    min-width:112px;
    flex: 0 1 auto;
    opacity: 0.8;
    img{
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";