
.chat_page{
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    position: relative;
    // height: 100vh;
    .model{
        width: 250px;
        height: 100%;
        border-right: 1px solid #e6e7eb;
    }
    .chat{
        flex:1;
        display: flex;
    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";