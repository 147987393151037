.SmallFlexCube{
  .utility_header{
   display: flex;
   width: 80%;
   margin: 0 auto 40px;
   flex-flow: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   h4{
     color:#4a4a57;
     font-size: 14px;
     line-height: 1;
     font-weight: 400;
     letter-spacing: 0.5px;
     text-transform: uppercase;
   }
   h2{
     margin-top: 0;
     margin-bottom: 0;
     font-family:Sora, sans-serif;
     font-size:36px;
     line-height: 1;
     font-weight: 500;
     letter-spacing: 0;
   }
  }
  .features_component{
   display: flex;
   flex-direction: column;
   align-items: stretch;
   .flex_item{
    margin-bottom:50px;
   }
  }
  .show_cube{
    margin-bottom:30px;
  }
}
.flex{
  justify-content:space-between;
  align-items: center;
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";