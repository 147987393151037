.WorkCube {
  border-radius: 32px;
  background-image: linear-gradient(180deg, @primaryColorBG, #fafafa);
  display: flex;
  padding: 77px 77px 32px;
  flex-flow: column;
  place-content: center;
  align-items: center;
  // gap: 25.6px;
  margin: 48px 0;

  .hero_text {
    h3 {
      margin-top: 0;
      margin-bottom: 0;
      font-family: Sora, sans-serif;
      font-size: 60px;
      line-height: 1;
      font-weight: 500;
      letter-spacing: 1px;
      span {
        color: @primaryColor;
      }
    }

    p {
      text-align: center;
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      color: @textColor;
      padding: 16px 0;
    }

    .jump {
      justify-content: center;
      margin-bottom: 20px;
      .ant-btn {
        padding: 24px;
        font-size: 17px;
        line-height: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
      }

      .started {
        color: #fff;
        background-image: linear-gradient(99.86deg, @primaryColor, darken(@primaryColor, 53%));
        margin-right: 16px;
        border: none;
      }

      .demo {
        color: @textColor;
      }

      .started:hover {
        background-color: @primaryColor;
        color:#fff;
      }

      .demo:hover {
        color: #fff;
        background-color: @otherColor;
      }
    }
  }
  .hero_img{
    display: flex;
    justify-content: space-between;
    align-items: center;
    // gap:20px;
    .Switch_cube{
      width: 460px;
      height: 460px;
      border-radius:50px;
      overflow: hidden;
      border: 10px solid #fff;
      // img{
      //   width: 100%;
      //   height: 100%;
      //   object-fit: contain;
      //   border-radius: 40px;
      // }
    }
    .sample{
       div{
        width:196px;
        height: 196px;
        border-radius:50px;
        margin: 10px;
        border: 10px solid #fff;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 40px;
        }
       }
    }
  }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";