.upload_style {
    width: 100%;

    .upload_style_warp {
        width: 100%;
        height: 60px;
        display: block;
        border-radius: 20px;
        background-color: #D3E9FE !important;

        .upload_in {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: @primaryColor ;
            font-size: 30px;
            i{
                font-size: 25px;
            }
        }
    }
}

.upload_done {
    width: 100%;

    .upload_style_warp {
        width: 100%;
        height: 100px;
        border-radius: 20px;
        background-color: #D3E9FE !important;
        display: flex;
        align-items: center;
        justify-content: center;
        .show_image {
            width: 136px;
            height: 85px;
            padding: 5px;
            border-radius: 10px;
            background-color: #94C9FB !important;

            img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                object-fit: cover;
            }
        }
    }


    .show_image {
        position: relative;

        .close_btn {
            font-size: 22px;
            position: absolute;
            top: 5px;
            right: 10px;
            color: @senceColor ;
        }
    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";