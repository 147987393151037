#loading{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    .loading_animat{
        width: 100%;
        height: 100%;
        max-height: 80px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .loading_text{
            // margin-left: 5px;
        }
    }
    img{
        margin: 0 auto;
        // width: 100%;
        height: 100%;
        max-width: 146px;
        max-height: 117px;
    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";