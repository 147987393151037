@import '../../../../theme.module.less';
.header {
    position: sticky;
    top: 0;
    z-index: 100;
}
.nav_bar {
    width: 100%;
    padding: 0 50px;
    display: flex;
    justify-content:space-between;
    align-items: center;
    height: 75px;
    background-color: #fafafa;
    border-bottom: 1px solid #E7EBEE;
        .logo_menu{
            width:50px;
            height:50px;
        }
        .logo {
           height: 100%;
           a{
             width: 100%;
             height: 100%;
             display: block;
             img{
                width: 100%;
                height: 100%;
                object-fit: contain;
               }
           }

        }



    .nav_list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left:20%;
        flex: 1;
        // min-width:1370px;
        .nav_item {
            height: 100%;
            position: relative;

            .nav_title {
                height: 100%;
                display: flex;
                align-items: center;

                .draw_tyle {
                    font-size: 10px;
                    margin-left: 5px;
                    font-weight: bold;
                }

                a {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    color:@textColor;
                    font-size: 18px;
                    // &.active {
                    //     color: @primaryColor;
                    // }
                }
            }

            .drop_box {
                position: absolute;
                background-color: #fff;
                padding:10px;
                border-radius: 10px;
                width: 200px;
                .drop_item{
                    width:auto;
                    padding: 10px;
                    border-radius: 5px;
                    display: flex;
                    justify-content:flex-start;
                    align-items: center;
                    font-size: 18px;              
                    a{
                        color:@textColor;
                    }
                    .icon{
                        margin-right: 10px;
                        width: 20px;
                        height: 20px;
                        background-color:#fafafa;
                        display: flex;
                        justify-content:flex-start;
                        align-items: center;
                        .iconfont{
                            font-size:26px;
                        }
                    }
                }
                .drop_item:hover{
                    background-color: #fafafa;
                    border-radius: 5px;
                    // a{
                    //     color: @primaryColor;
                    // }
                    // .icon{
                    //     color:@primaryColor;
                    // }
                }
            }
        }

        .nav_item:not(:first-child) {
            margin-left: 25px;
        }
    }
}

.header_mobile{
    height: 60px;
}

// .nav_bar_mobile {
//     padding: 0;
//     height: 60px;
//     display: block;
//     .logo_menu {
//         width: 100%;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         padding: 0 10px;
//         height: 100%;
//         .drop_icon {
//             width: 40px;
//             height: 40px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             font-size: 26px;
//         }

//         .logo {
//             height: 100%;
//             img{
//                 height: 100%;
//             }
//         }
//     }

//     .nav_list {
//         flex-direction: column;
//         border: none;
//         width: 100%;
//         margin: 0;
//         height: 100vh;
//         overflow: hidden;
//         align-items: flex-start;
//         animation: dropani .5s ;
//         background-color: #fff;
//         .nav_item {
//             align-items: flex-start;
//             height: initial;
//             &:not(:first-child){
//                 margin-left: 0;
//             }
//             .nav_title {
//                 height: initial;

//                 a {
//                     height: initial;

//                 }
//             }

//             .drop_box {
//                 position: initial;

//             }
//         }
//     }
// }
// @keyframes dropani {
//     0%{
//         height: 0vh;
//     }
//     100%{
//         height: 100vh;
//     }
// }
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";