.bottom_bar{
    width: 100%;
    padding:50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #151516;
    .main{
        padding: 30px 0  20px;
        margin: 0 auto;
        width: 1000px;
        border-top: 1px solid #fff;
        color:#fff;
        display: flex;
        justify-content:space-between;
        align-items: center;
    }
    .jump{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        div{
            margin: 0 20px;
        }
    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";