.common_block{
    color: @senceColor;
    user-select: none;
    .common_header{
        margin-bottom:5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;

        .common_title{
            margin-right: 10px;
            font-weight: bold;
        }
        .contry_icon{
            cursor: pointer;
        }
    }
    .common_content{
        background-color: fade(@senceColor,10%);
        border-radius: 10px;
        padding: 10px;
        color: @textColor;
    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";