.konvajs-content{
    width: 100% !important;
    height: 100% !important;
    canvas{
        width: 100% !important;
        height: 100% !important;
    }
   
}
.stageBox{
    margin: 0 auto;
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";