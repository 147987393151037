.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    .header_box{
        flex-shrink: 0;
    }
    .content_layout {
        display: flex;
        width: 100%;
        flex:1;
        height: 0;
    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";