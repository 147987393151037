.common_block{
    width: 100%;
    // padding-bottom: 20px;
    padding-top: 20px;
    .title{
        color: #3f3f3f;
        font-size: 24px;
        padding-bottom: 10px;
    }
    &.border{
        border-bottom: 1px solid #ccc;;
    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";