.flex_tab{
    display: flex;
    justify-content: center;
    align-items: center;
    div{
        padding:5px 15px;
        border-radius:30px;
        font-size: 18px;
        background-color: @BGColor;
        margin: 0 10px 20px;
        cursor: pointer;
    }
    div:hover{
        background-color: rgba(21, 21, 22, 0.03);
    }
    .active{
        background-color: rgba(21, 21, 22, 0.03);
    }
}


@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";