.Style_page{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 20px;
    font-size: 14px;
    overflow: auto;
    .classify_title{
        display: flex;
        justify-content: center;
        align-items: center;
        i{
            color:#2197fc;
            font-size: 25px;
            margin-right:10px;
        }
        p{
            color:#000000;
            font-size:20px;
            font-weight: bolder;
            text-align: center;
            text-decoration: underline;
        }
    }
    .desc_box{
        // margin-top: 20px;
        .desc_title{
            color:@senceColor;
            padding:15px 0 6px;
            font-weight: bolder;
            font-size: 18px;
            text-align: center;
        }

    }
    .submit{
        margin-top:30px;
    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";