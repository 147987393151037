
.big_card_page{
  cursor: pointer;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration:0.8s;
   .gradient-bg {
    height: 100%;
    border-radius: 30px;
    .flex{
      display: flex;
      justify-content: space-between;
      align-items:stretch;
      padding:20px;
      border-radius:30px;
      height: 100%;
      .explain{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .txt{
          color:@textColor;
          font-size: 16px;
          flex:1;
          align-self:flex-start;
          h4{
             font-weight: bolder;
             font-size:28px ;
             margin-bottom:5px;
           }
           p{
            line-height: 20px;
           }
        }
        .btn{
          text-align: left;
          // .ant-btn.ant-btn-round.ant-btn{
          //   background-color: #fff;
          //   color:@textColor;
          //   font-size: 18px;
          // }
          // .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
          //    color:@primaryColor;
          // }
          .started{
            color: #fff;
            background-image: linear-gradient(99.86deg, @primaryColor, darken(@primaryColor, 53%));
            margin-right: 16px;
            border: none;
          }
          .started:hover {
            background: @primaryColor;
            color:#fff;
          }
        }
      }

    }
   }

}
.big_card_page:hover{
  transform: scale(1.02);
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";