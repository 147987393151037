.work_frame {
    flex: 1;
    display: flex;
    text-align: center;
    padding: 20px;
    .img_show_list {
        width: 100%;
        display: flex;
        flex-direction: column;
        .frame_header {
            width: 200px;
            margin: 0 auto;
            .frame_header_title{
                color:@senceColor;
                font-size: 20px;
                font-weight: bolder;
            }
            .frame_header_size {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .slider {
                    flex: 1;

                    margin: 5px 0 0 10px;
                }
            }
        }

        .frame_warp {
            flex: 1;
            height: 0;
            overflow: auto;
            margin-top: 20px;
            .frame_content {
                // height: 100%;
                height: auto;
                display: flex;
                flex-wrap: wrap;
                .image_box {
                    width: 20%;
                    background-color: #d3e9fe;
                    height: fit-content;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    font-size: 0;
                    border-radius: 20px;
                    overflow: hidden;
                    .image_temphold {
                        // padding-bottom: 100%;
                        padding-bottom:70%;
                        position: relative;
                        .loading{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                        }

                    }
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }

}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";