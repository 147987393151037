
.text_image{
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    position: relative;
    // height: 100vh;
    .config{
        width: 300px;
        height: 100%;
        background-color: fade(@senceColor,8%);
        border-right: 1px solid #e6e7eb;
    }
    .work_frame{
        flex:1;
        display: flex;
    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";