#radio_custom{
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-radio-button-wrapper{
        border: 2px solid @pinkColor;
        color:@pinkColor;
        font-size: 16px;
        line-height: 25px;
    }
    .ant-radio-button-wrapper:first-child{
        border-left: 1px solid @pinkColor;
        border-radius:20px;
    }
    .ant-radio-button-wrapper:nth-child(2){
        border-radius:20px;
        margin: 0 10px;
    }
    .ant-radio-button-wrapper:last-child{
        border-radius:20px;
    }
    .ant-radio-button-wrapper:not(:first-child)::before{
        background-color: transparent;
    }
    .ant-radio-button-wrapper:hover {
        color: #fff;
        background-color: @pinkColor;
        &::before{
        background-color: transparent;

        }
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
        background: @pinkColor;
        border-color: @pinkColor;
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        background: @pinkColor;
        border-color: @pinkColor;
        color:#fff
    }
}

@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";