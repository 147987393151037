.ShowBox{
  display: flex;
  padding:24px;
  flex-flow: column;
  flex: 1 1 0%;
  // gap:64px;
  border-radius:26px;
  background-color:#fff;
  .icon-rect-medium{
    display: flex;
    width:48px;
    height:48px;
    padding:8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #f3f7fb;
    margin-bottom:30px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .insights_text-wrapper{
    display: flex;
    flex-flow: column;
    // gap:4.8px;
    .heading-style-h1{
      font-family:Sora, sans-serif;
      font-size:48px;
      line-height: 1;
      font-weight: 500;
      color:#151516;
      margin-bottom: 20px;
    }
    .text-size-small{
      font-family:Inter, sans-serif;
      color: #4a4a57;
      font-size: 14px;
    }
  }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";