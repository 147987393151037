#limit_area {
    // margin-bottom: 30px;
    position: relative;
    border-radius: 30px;
    box-shadow: 0px 8px 0px 1px @sliderColorLight;
    border: 1px solid @sliderColorLight;
    padding-top: 10px;
    background-color: #fff;
    overflow: hidden;
    .limit {
        position: absolute;
        bottom: 10px;
        right: 30px;

    }

    textarea {
        outline: none;
        // border-style: none;
        padding-bottom: 20px;
        border: none;
        box-shadow: none;

        &:hover, &:focus,&:focus-within{
            outline: none;
            border: none;
            box-shadow: none;
        }
        
    }
    .ant-input {
        &::placeholder {
          color: #333 !important;
        }
      }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";