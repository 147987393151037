@import '../../../../theme.module.less';

.model_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;

    .tips {
        color: #c5c5c5;
    }

    .model_warp {
        flex: 1;
        // overflow-y: scroll;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        height: 0;

        .model_list {
            flex: 1;
            overflow: auto;
            padding: 5px;

            .model_item {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 5px 10px;
                border-radius: 10px;
                overflow: hidden;
                cursor: pointer;
                margin-bottom: 10px;
                font-weight: bold;

                &:hover {
                    background-color: #e9ecfb;
                }

                .model_img {
                    width: 45px;
                    height: 45px;
                    flex-shrink: 0;

                    img {
                        width: 100%;
                    }
                }

                .model_name {
                    flex: 1;
                  font-size: 16px;
                    margin-left: 15px;
                }

                &.active {
                    background-color: @primaryColor;

                    color: #fff;
                }
            }
        }

    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";