
.tt_Image_page{
  width: 100%;
  position: relative;
  // min-height: 100vh;
  background-color: @BGColor;
  .padding-global{
    width: 90%;
    max-width:1024px;
    margin-right: auto;
    margin-left: auto;
    padding:40px 0;
  }
//   .padding-section-medium {
//     padding: 80px 0;
//  }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";