.FlexImg{
    order: 0;
    width: 500px;
    flex-shrink: 0;
    // gap:20px;
      img{
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
        display: block;
      }
  }
.FlexText{
  text-align: left;
  color:@textColor;
    h3{
      font-size: 24px;
      letter-spacing:1px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    p{
      font-size:16px;
      line-height:20px;
      margin-bottom: 10px;
    }
}

@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";