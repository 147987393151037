.chat_main{
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #f9f9f9;
    .chat_content{
        display: flex;
        flex: 1;
        height: 0;
        .chat_record{
            width: 80%;
            margin: 0 auto;
            padding: 30px 0 50px;
        }
        .empty_record{
            flex:1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .record_model{
                font-weight: bold;
                font-size: 1.5em;
                margin-bottom: 10px;
            }
        }
    }
    .chat_input{
        width: 100%;
        min-height: 40px;
        margin-bottom: 10px;
        .input_box{
            width: 80%;
            margin: 0 auto;
            // border: 1px solid @primaryColor;
            box-shadow:0px 0px 5px  @primaryColor;
            border-radius: 8px;
            padding:5px 10px ;
            overflow: hidden;
            display: flex;
            align-items: center;
            .input_icon{
                font-size: 20px;
            }
            .input{
                flex: 1;
                margin: 0 5px;
                .inputarea{
                    border: none;
                    box-shadow: none;
                }
            }
           
            .sent_btn{
                width: 30px;
                height: 30px;
                background-color:lighten(@primaryColor,30%);
                color: @primaryColor;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                cursor: pointer;
            }
        }
        // background-color: red;
    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";