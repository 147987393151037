.chat_record_box{
    width: 100%;
    height: 100%;
    overflow: auto;
    .record_item{
        width: 100%;
        border-radius: 8px;
        padding: 10px 20px;
        margin-bottom: 20px;
        font-size: 14px;
        &.user_bg{
            background-color: #fff;
        }
        &.answer_bg{
            background-color: @primaryColorBG;
        }
        .record_header{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .header_img{
                width: 30px;
                height: 30px;
                background-color: @primaryColorBG;
                border-radius: 5px;
                overflow: hidden;
                img{
                    width: 100%;
                }
            }
            .header_name{
                margin-left: 10px;
                font-weight: bold;
                font-style: 16px;
            }
            .copy{
                margin-left: auto;
                cursor: pointer;
                &:hover{
                    color: @primaryColor;

                }
            }
        }
        .record_content{
            line-height: 1.3;
        }
    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";