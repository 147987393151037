
.history_box{
    position: relative;
    height: 100%;
    .open_history{
        left: 0;
        top: 0;
        transform: translateX(-100%);
        position: absolute;
       
    }
    .history_icon{
        font-size: 20px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .history_contain{
        height: 100%;
        width:0;
        overflow: hidden;
        transition:all .8s;
    .history_content{
       width: 250px;
        height: 100%;
        border-left: 1px solid #e6e7eb;
        padding: 10px;
        display: flex;
        flex-direction: column;
        .hide_history{
            margin-bottom: 10px;
        }
        .tips{
            color: #c5c5c5;
            margin-bottom: 10px;
        }
        .history_warp{
            flex: 1;
            height: 0;
                margin-bottom: 20px;
                .history_List{
                height: 100%;
                overflow-y: scroll;
            }
            .history_item{
                display: flex;
                align-items: center;
                color: #6c7174;
                justify-content: flex-start;
                padding: 10px 5px;
                margin-bottom: 5px;
                border-radius: 5px;
                cursor: pointer;
                &:hover{
                    background-color: @primaryColorBG;
                }
            }
            .item_icon{
                font-size: 12px;
            }
            .item_title{
                flex:1;
                margin: 0 5px;
            }
            .delete_icon{
                &:hover{
                    color: @primaryColor;
                }
            }
        }
        .create_btn{
            width: 150px;
            height: 30px;
            font-size: 14px;
            color: #fff;
            background-color: @primaryColor;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            // margin-right: 20px;
            .creat_icon{
                font-size: 12px;
                margin-right: 5px;
            }
        }
    }
}
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";