  .carousel_page{
    .slick-track{
    }
    .slick-slide{
      //  width: 336px !important;
      //  height: 331px !important; 
       img{
        width: 100% ;
        height: 100%;
        object-fit:contain;
       }
    }
    .ant-carousel .slick-dots-bottom{
       bottom:-26px;
    }
    .ant-carousel .slick-dots li{
        width:10px;
        height:10px;
        border-radius: 50%;
        button{
          background: @senceColor;
          width: 10px;
          height:10px;
          border-radius: 50% ;
      }
    }
    .ant-carousel .slick-dots li.slick-active{     
        width: 10px !important;
        height: 10px;
        button{
          background: @primaryColor;
        }
    }
  .flex{
    justify-content:space-between;
    align-items: center;
  }
  }

@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";