.img_control {
    position: relative;
    font-size: 16px;
    border-radius: 20px;
    .img{
        border-radius: 20px;
        font-size: 0;
        img{
            border-radius: 20px;
        }
    }
    .control_box {
        position: absolute;
        bottom:0px;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        display: none;
        &>div {
            min-width: 100px;
            margin:0 5px 10px;
            background-color: #fff;
            border: 1px solid #E7EBEE;
            border-radius: 20px;
            cursor: pointer;
            padding: 5px;
        }
        .cube_flex{
            display: flex;
            justify-content: center;
            align-items: center;
            color:@textColor;
            .icon{
                width:24px;
                height:24px;
                border-radius: 50%;
                background: @sliderColorBG;
                display: flex;
                justify-content: center;
                align-items: center;
                color:@primaryColor;
                font-size:20px;
                margin-right: 5px;
            }
            a{
                color:@textColor;
            }
        }
    }
    .imgloading{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .3);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }
    &:hover .control_box{
        display: flex;
    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";