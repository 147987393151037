.AskCube{
  background-image: linear-gradient(180deg, rgba(54, 97, 246, 0.1), #f3f7fb);
  padding:77px;
  flex-flow: column;
  border-radius: 38px;
  width: 1000px;
  margin:100px auto;
  .faq_header{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    // gap:13px;
    text-align: center;
    margin-bottom:32px;
    h3{
      margin: 0 auto;
      font-family:Sora, sans-serif;
      font-size: 36px;
      line-height: 1;
      font-weight: 500;
      letter-spacing:1px;
    }
  }
  .faq_list{
     .ant-collapse{
       background-color: transparent !important;
     }
     .ant-collapse-header-text{
        margin-top: 0;
        margin-bottom: 0;
        font-family:Sora, sans-serif;
        font-size:18px;
        line-height: 1;
        font-weight:bold;
        letter-spacing:1px;
     }
     .ant-collapse-content-box{
        p{
          font-size: 16px;
          line-height:22px;
          margin: 0;
        }
     }
     .ant-collapse-large >.ant-collapse-item >.ant-collapse-content>.ant-collapse-content-box{
      padding: 16px;
     }
     .ant-collapse>.ant-collapse-item >.ant-collapse-header .ant-collapse-arrow{
      font-size: 20px;
     }
  }
}
.flex{
  display: flex;
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";