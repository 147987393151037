.CategoryCube{
  .section-header{
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;
    h2{
      color: #4a4a57;
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      text-align:left;
      margin-bottom:15px;
    }
    h3{
      // margin: 0 auto;
      font-family:Sora, sans-serif;
      font-size:24px;
      line-height: 1;
      font-weight: 500;
      letter-spacing: 1px;
      text-align:left;
    }
  }
  .text-align-center{
    text-align: center;
  }
  .insights_component{
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
    // gap: 38px;
    padding: 32px 0;

  }
  .show_cube{
    display: flex;
    // padding: 1.5rem;
    flex-flow: column;
    
    // gap:64px;
    width: 24%;
  }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";