.btn_ctrl{
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 100%;
    // height: 100%;
    width: 170px;
    height: 24px;
    line-height: 24px;
    background-color: @primaryColor;
    box-shadow:  0 0 0 6px #C3E1FD;
    // border:8px solid fadeout(@senceColor,10%);
    border-radius:25px;
    cursor: pointer;
    margin: 0 auto;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration:0.4s;
    p{
        color:#fff;
        font-weight: bolder;
        font-size:16px;
        line-height:22px;
        height: 100%;
    }
    &.active{
        
    }
}
.btn_ctrl:hover{
    transform: scale(1.02);
    box-shadow:  0 0 0 6px @primaryColor;
}
.btn_ctrl_color:hover{
    transform: scale(1.02);
    box-shadow:  0 0 0 6px rgb(124, 217, 172) !important;
}







@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";