
.home_page{
  width: 100%;
  background-color: #fafafa;
  // padding:48px 0;
  .flex{
    display: flex;
  }
  .flex_center{   
    align-items: center;
  }
  .flex_justify{
    justify-content: space-between;  
  }
  .cube{
    width:1000px;
    margin:96px auto;
  }
  .interval{
    padding-top: 0 !important;
  }
  .transform{
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    opacity: 1;
  }



  // .padding-section-medium {
  //    padding: 80px 0;
  // }


  .mainfeatures_heading{
    display: flex;
    flex-flow: column;
    // gap: 38px;
  }


  .padding-global{
    width: 90%;
    max-width:1024px;
    margin-right: auto;
    margin-left: auto;
    padding:40px 0;
  }








}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";