.SwitchCube{
  .section-header{
    display: flex;
    flex-flow: column;
    margin-bottom:20px;
    h2{
      color: #4a4a57;
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    h3{
      margin: 0 auto;
      font-family:Sora, sans-serif;
      font-size:36px;
      line-height: 1;
      font-weight: 500;
      letter-spacing:1px;
    }
  }
  .text-align-center{
    text-align: center;
  }
  .insights_component{
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
    // gap:38px;
    padding: 32px 0;
    .insights_tile {
      display: flex;
      padding:24px;
      flex-flow: column;
      flex: 1 1 0%;
      // gap:64px;
      border-radius:25px;
      background-color:#fff;
      .icon-rect-medium{
        display: flex;
        width: 48px;
        height: 48px;
        padding:8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius:12px;
        background-color: #f3f7fb;
      }
      .insights_text-wrapper{
        display: flex;
        flex-flow: column;
        // gap:13px;
        .heading-style-h1{
          font-family:Sora, sans-serif;
          font-size:48px;
          line-height: 1;
          font-weight: 500;
          color:#151516;
        }
        .text-size-small{
          font-family:Inter, sans-serif;
          color: #4a4a57;
          font-size:14px;
        }
      }
    }
  }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";