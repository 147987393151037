.yaber_cube{
   display: flex;
   background-color: #fff;
   padding:32px;
   border-radius:32px;
   .leftImg{
    width: 50%;
    order: -1;
    flex: 0 1 auto;
    aspect-ratio: 1 / 1;
    border-radius: 32px;
    img{
      display: inline-block;
      width: 100%;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      border-radius:32px;
    }
   }
   .rightTxt{
    display: flex;
    width: 100%;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 1 0%;
    // gap: 52px;
    padding-left:32px;
    h4{
      margin-top: 0;
      margin-bottom: 0;
      font-family: Sora, sans-serif;
      font-size: 36px;
      line-height: 1;
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 20px;
    }
    p{
      font-size:16px;
      margin-bottom: 20px;
    }
    .ant-btn{
      border: 1px solid #e7ddda;
      display: flex;
      padding:24px;
      justify-content: center;
      align-items: center;
      font-size:20px;
      color:#151516;
      background-color: #fff;
    }
    .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
      background-color: @otherColor;
      border: 1px solid @otherColor;
      color:#fff;
    }
   }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";