
.Image_list_page{
   display: flex;
   flex-wrap:wrap;
   justify-content: space-between;
   align-items: stretch; 
   // gap:25px;
   .big_card_page{
      width:65%;
      box-sizing: border-box;
      flex-shrink: 0;
      border-radius: 30px;
      margin-bottom: 20px;
   }
   .show_cube{
        width:31%;
        flex-shrink: 0;
        box-sizing: border-box;
        transition-property: all;
        transition-timing-function: cubic-bezier(.4,0,.2,1);
        transition-duration:0.8s;
        border-radius: 30px;
        margin-bottom: 20px;
   }
   .show_cube:hover{
    transform: scale(1.02);
  }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";