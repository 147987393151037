/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  text-decoration: none;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

div {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #000;
  display: inline-block;
}

/* 全局滚动条的样式 */
::-webkit-scrollbar {
  width: 5px;
  /* 设置滚动条的宽度 */
  height: 8px; /* 设置滚动条的高度 */
}

/* 滚动条轨道的样式 */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* 设置轨道的背景颜色 */
}

/* 滚动条的样式 */
::-webkit-scrollbar-thumb {
  background: #888;
  /* 设置滚动条的背景颜色 */
  border-radius: 10px;
}

/* 当鼠标悬停在滚动条上时的样式 */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* 设置滚动条悬停时的背景颜色 */
}

.line_ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ant-slider {
  padding-top: 0;
  padding-bottom: 0;
  height: 20px;

  .ant-slider-handle {
    top: 0px;
  }

  .ant-slider-step {
    top: 0;
  }
  .ant-slider-rail {
    border-radius: 10px;
  }
  
  .ant-slider-track {
    border-radius: 10px 0 0 10px;
  }
  
  .ant-slider {
    margin: 0;
  }
  
  .ant-slider-handle {
    // left: 15% !important;
  }
}


@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";