@font-face {
  font-family: "iconfont"; /* Project id 4665671 */
  src: url('iconfont.woff2?t=1726197262733') format('woff2'),
       url('iconfont.woff?t=1726197262733') format('woff'),
       url('iconfont.ttf?t=1726197262733') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fanhui:before {
  content: "\e77e";
}

.icon-xiazai:before {
  content: "\eb13";
}

.icon-shangchuantupian:before {
  content: "\e60b";
}

.icon-anniu_jiantouzhankai:before {
  content: "\eb03";
}

.icon-huopinfenliu:before {
  content: "\eb39";
}

.icon-tingzhi:before {
  content: "\eae1";
}

.icon-zanting:before {
  content: "\eaea";
}

.icon-jiazai_shuaxin:before {
  content: "\eaf5";
}

.icon-daohang:before {
  content: "\eaf3";
}

.icon-jiahao:before {
  content: "\eaf4";
}

.icon-shouqi:before {
  content: "\e63e";
}

.icon-tongyong-zhankai:before {
  content: "\e61b";
}

.icon-jiantou_xiangshang:before {
  content: "\eb0a";
}

.icon-bianji:before {
  content: "\eabd";
}

.icon-fuzhi:before {
  content: "\eac1";
}

.icon-zhuti_tiaosepan:before {
  content: "\eaec";
}

.icon-bangzhu:before {
  content: "\eaef";
}

.icon-guanbi:before {
  content: "\eaf2";
}

.icon-lishijilu:before {
  content: "\eaf7";
}

.icon-shanchu:before {
  content: "\eafb";
}

.icon-quanjushezhi:before {
  content: "\eafc";
}

.icon-sousuo:before {
  content: "\eafe";
}

.icon-duanxin:before {
  content: "\eb23";
}

.icon-gerentouxiang:before {
  content: "\eb24";
}

