.img_slider {
    width: 100%;
    height: 100%;
    position: relative;
    
    &>.back,
    &>.front {
        width: 100%;
        height: 100%;
        background-size: contain;
        position: absolute;
        overflow: hidden;
        .img_content{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        background-size: contain;
            
        }
    }

    &>.handle {
        position: absolute;
        left: 50%;
        height: 100%;
        transform: translateX(-50%);
        .handle_bar {
            height: 100%;
            width: 4px;
            background-color: #fff;
            top: 0;
            border-left: 1px solid #cccccc;
            border-right: 1px solid #ccc;
        }

        .handle_btn {
            width: 60px;
            height: 60px;
            border-radius: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba(255, 255, 255, .5);
            backdrop-filter: blur(6px);
            cursor: pointer;
            user-select: none;
            img {
                max-width: 100%;
                cursor: pointer;
                pointer-events: none;
            }

        }


    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";