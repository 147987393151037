.text_draw {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    &>div{
    padding: 20px;

    }
    .left {
        width: 30%;
        flex-shrink: 0;
        .num_box {
            display: flex;

            .num_item {
                width: 25%;
                height: 65px;
                padding: 0 5px;
            }
        }
        .draw_size_box{
            display: flex;
            align-items: center;
            .size_ctrl{
                flex: 1;
            }
        }
    }

    .right {
        flex: 1;
    }
    .active{
        position: relative;
    }
    .active::after{
        border: 2px solid;
        border-image: linear-gradient(to right, #8f41e9, #578aef) 1;
    }
    .draw_ctrl{
        .ant-btn{
            border: 5px solid @sliderColorBG;
        }
    }
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";