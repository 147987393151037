
.small_card_page{
  height: 320px;
  width: 100%;
  cursor: pointer;
  border-radius: 30px;
  .gradient-bg {
    height: 100%;
    border-radius: 30px;
    .block{
      padding:10px;
      border-radius:30px;
      height: 100%;
      .img{
        // margin-bottom: 20px;
        text-align: center;
      }
      .explain{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .txt{
          color:@textColor;
          font-size: 16px;
          flex:1;
          align-self:flex-start;
          margin-bottom:5px;
          width: 100%;
          h4{
             font-weight: bolder;
             font-size:28px ;
             margin-bottom:5px;
             text-align: center;
           }
           p{
            min-height: 36px;
            line-height: 20px;
            text-align: center;
           }
        }
        .btn{
          text-align:center;
          // .ant-btn.ant-btn-round.ant-btn{
          //   background-color: #fff;
          //   color:@textColor;
          //   font-size: 18px;
          // }
          // .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
          //    color:@primaryColor;
          // }
          .started {
            color: #fff;
            background-image: linear-gradient(99.86deg, @primaryColor, darken(@primaryColor, 53%));
            margin-right: 16px;
            border: none;
          }
          .started:hover {
            background: @primaryColor;
            color:#fff;
          }
        }
      }
  }
  }

}

@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";