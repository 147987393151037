.slider_input{
    display: flex;
    align-items:flex-end;
    justify-content: space-between;
    // margin: 10px 0 0;
    border-radius: 40px;
    .slider{
        width: 90%;
        margin-right: 20px;
        .ant-slider{
            padding-top: 0;
            padding-bottom: 0;
            height: 20px;
            .ant-slider-handle{
                top: 0px;
            }
            .ant-slider-step{
                top:0;
            }
        }
        .ant-slider-rail{
            border-radius:10px;
        }
        .ant-slider-track{
            border-radius:10px 0 0 10px;
        }
        .ant-slider{
            margin: 0;
        }
        .ant-slider-handle{
            // left: 15% !important;
        }
    }
    .ant-input-number{
        border-radius:30px;
    }
}
.unit{
    display: inline-block;
    margin-left: 5px;
}
@hack: true; @import (reference) "/Users/kongfanjian/Documents/trusme-project/AIDrawWeb/src/theme.module.less";